.nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .logo {
    height: 32px;
  }

  .main-nav {
    display: grid;
    grid-template-columns: min-content 1fr max-content;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    height: 80px;

    & > div {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
