.header {
  margin: 0;

  &.red-gradient {
    background: var(--red-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.h1 {
    font-size: 64px;
  }

  &.h2 {
    font-size: 42px;
  }

  &.h3 {
    font-size: 28px;
  }

  &.h4 {
    font-size: 24px;
  }

  &.h5 {
    font-size: 20px;
  }

  &.h6 {
    font-size: 16px;
  }
}
