.banner {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--purple-50);

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    gap: 4px;
  }

  .close {
    border: none;
    background: none;
    position: absolute;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
