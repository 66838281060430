.button {
  cursor: pointer;
  border: 2px solid transparent;
  padding: 8px 16px;
  border-radius: 24px;
  font-weight: bold;
  width: min-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;

  & > * {
    height: 100%;
  }

  &.primary {
    border-color: var(--white);
    background-color: var(--red-50);
    color: var(--white);
    fill: var(--white);
    stroke: var(--white);

    &:hover {
      background-color: var(--red-60);
    }
  }

  .arrow {
    height: 16px;
  }
}
