.text {
  margin: 0;

  &.bold {
    font-weight: bold;
  }

  &.large {
    font-size: 19px;
  }

  &.medium {
    font-size: 16px;
  }

  &.small {
    font-size: 14px;
  }
}
