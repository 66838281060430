.link {
  text-decoration: none;
  display: flex;
  align-items: center;
  width: max-content;
  gap: 4px;

  &.active {
    text-shadow: 0.5px 0 0 currentColor;
  }

  &.underline {
    text-decoration: underline;
  }

  &.primary {
    color: var(--red-50);

    &:hover {
      color: var(--red-30);
    }
  }

  &.plain {
    color: inherit;

    &:hover {
      opacity: 0.8;
    }
  }

  &.nav {
    color: var(--navy-30);

    &:hover {
      color: var(--navy-50);
    }
  }

  &.sub-nav {
    font-size: 14px;
  }

  .arrow {
    height: 16px;
  }
}
