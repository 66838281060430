:root {
  --red-80: #590310;
  --red-70: #87061b;
  --red-60: #b40a23;
  --red-50: #e20626;
  --red-40: #e02945;
  --red-30: #ec766d;
  --red-20: #f2a19b;
  --red-10: #f7cfcb;

  --navy-50: #000033;
  --navy-45: #33335c;
  --navy-40: #4c4c6e;
  --navy-30: #5f5f82;
  --navy-20: #b2b2c1;
  --navy-10: #e5e5eb;

  --beige-100: #686563;
  --beige-80: #b7b3b1;
  --beige-70: #cecac7;
  --beige-60: #dad5d2;
  --beige-50: #e5e0dd;
  --beige-40: #eae6e4;
  --beige-30: #efeceb;
  --beige-20: #f5f3f1;
  --beige-10: #faf9f8;

  --purple-50: #5c44ff;
  --purple-10: #f5f4ff;
  --white: #ffffff;

  --red-gradient: linear-gradient(0deg, var(--red-80) 0%, var(--red-50) 100%);
}
